import React from "react";
import './ShowIndex.css';
import ShowIndexItem from "../ShowIndexItem/ShowIndexItem";

function ShowIndex(): JSX.Element {
    const dates: Record<string, Record<string, string>> = { 
        "hill": {
            "month": "December",
            "check": "31",
            "day": "31st",
            "time": "",
            "year": "2024",
            "maps": "https://www.google.com/maps/dir//2709+Hillcrest+Ave,+Antioch,+CA+94531/@37.9948892,-121.8653854,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x8085571be3e4d5bb:0xfb08322922ff6846!2m2!1d-121.7829844!2d37.9949179?entry=ttu&g_ep=EgoyMDI0MTIwNC4wIKXMDSoASAFQAw%3D%3D",
            "name": "Hillcrest Restaurant and Taphouse"
        },
        "st1": {
            "month": "January",
            "check": "4",
            "day": "4th",
            "time": "9pm-1am",
            "year": "2025",
            "maps": "https://www.google.com/maps/dir//1410+Old+County+Rd,+Belmont,+CA+94002/@37.5166353,-122.3526618,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x808f9f5408becbfd:0x76940f3229fb3e20!2m2!1d-122.2702608!2d37.5166639?entry=ttu&g_ep=EgoyMDI0MTIwNC4wIKXMDSoASAFQAw%3D%3D",
            "name": "St. James Gate"
        },
        "cjs": {
            "month": "February",
            "check": "1",
            "day": "1st",
            "time": "8pm-12am",
            "year": "2025",
            "maps": "https://www.google.com/maps/dir//cjs+saloon/@37.9936286,-122.1943142,10z/data=!4m8!4m7!1m0!1m5!1m1!1s0x80856696a922960f:0xf10181bbf83d8329!2m2!1d-122.0675558!2d37.9795755?entry=ttu&g_ep=EgoyMDI0MTIwNC4wIKXMDSoASAFQAw%3D%3D",
            "name": "CJ's Saloon"
        },
        "st2": {
            "month": "February",
            "check": "7",
            "day": "7th",
            "time": "9pm-1am",
            "year": "2025",
            "maps": "https://www.google.com/maps/dir//1410+Old+County+Rd,+Belmont,+CA+94002/@37.5166353,-122.3526618,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x808f9f5408becbfd:0x76940f3229fb3e20!2m2!1d-122.2702608!2d37.5166639?entry=ttu&g_ep=EgoyMDI0MTIwNC4wIKXMDSoASAFQAw%3D%3D",
            "name": "St. James Gate"
        },
        "clayton": {
            "month": "February",
            "check": "15",
            "day": "15th",
            "time": "9pm-1am",
            "year": "2025",
            "maps": "https://www.google.com/maps/dir//clayton+club/@37.9363078,-122.0366858,11.49z/data=!4m8!4m7!1m0!1m5!1m1!1s0x80855f128f35a14d:0x4205df8cfb67d883!2m2!1d-121.9350468!2d37.9410858?entry=ttu&g_ep=EgoyMDI0MTIwNC4wIKXMDSoASAFQAw%3D%3D",
            "name": "Clayton Club"
        },
        "clayton2": {
            "month": "March",
            "check": "8",
            "day": "8th",
            "time": "9pm-1am",
            "year": "2025",
            "maps": "https://www.google.com/maps/dir//clayton+club/@37.9363078,-122.0366858,11.49z/data=!4m8!4m7!1m0!1m5!1m1!1s0x80855f128f35a14d:0x4205df8cfb67d883!2m2!1d-121.9350468!2d37.9410858?entry=ttu&g_ep=EgoyMDI0MTIwNC4wIKXMDSoASAFQAw%3D%3D",
            "name": "Clayton Club"
        },
        "st3": {
            "month": "March",
            "check": "21",
            "day": "21st",
            "time": "9pm-1am",
            "year": "2025",
            "maps": "https://www.google.com/maps/dir//1410+Old+County+Rd,+Belmont,+CA+94002/@37.5166353,-122.3526618,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x808f9f5408becbfd:0x76940f3229fb3e20!2m2!1d-122.2702608!2d37.5166639?entry=ttu&g_ep=EgoyMDI0MTIwNC4wIKXMDSoASAFQAw%3D%3D",
            "name": "St. James Gate"
        },
    };
    return (
        <div id="show-section">
            <div className="show-section-wrapper">
                <h1 className="show-title">
                    LuvSikAngel Live!
                </h1>                
                {Object.entries(dates).map(([key, value]: [string, Record<string, string>]): JSX.Element | null => { 
                        return (
                            <ShowIndexItem // initialize showIndexItem component for each valid date object
                                key={key} 
                                location={value.name}
                                maps={value.maps}
                                year={value.year}
                                month={value.month}
                                day={value.day}
                                time={value.time}
                            />
                        );
                })}
            </div>
        </div>
    );
};
export default ShowIndex;
